import React from 'react';
import './ARButton.css';
import 'antd/dist/antd.css';
import {MobileView, BrowserView, AndroidView, IOSView, isIOS, isAndroid} from "react-device-detect";
import {Button} from 'antd';
import '@google/model-viewer';
import ReactGA from 'react-ga';
 


class ARButton extends React.Component{
    
    render(){ 
        let arlink = isIOS ? this.props.usdz : (isAndroid ? this.props.glb: null);
        let glbLink =  this.props.glb;

        //const model = document.createElement('model-viewer');
        //model.src = 'https://threekit.s3.amazonaws.com/glb/BeoSound+2+Silver.glb';
        //console.log(model);
        //document.body.appendChild(model);
        return(
        <div>
        <MobileView>
            <IOSView>
            <Button onClick={this.clickIos}>View in AR</Button>
            <div style={{display:"none"}}>
                <a href={arlink} rel="ar" id="ar-ios-link">
                    <img style={{display:"none"}} src="https://venturebeat.com/wp-content/uploads/2019/11/threekit-logo.png?fit=400%2C200&strip=all" alt="hidden iOs ar link"/>
                </a>
            </div>
            </IOSView>
            <AndroidView>
                <Button onClick={this.clickAndroid}>View in AR</Button>
                
            </AndroidView>
        </MobileView>
        
        <BrowserView>
            {console.log("glb")}
            {console.log(glbLink)}

            
            <Button href={arlink} disabled={true}>View on AR Compatible Device</Button>
        </BrowserView>
        </div>

    );}
    componentDidMount(){

    }
    clickAndroid = ()=>{
        console.log("trying to launch Android Player");
        ReactGA.initialize('UA-63905846-8');
        ReactGA.pageview(this.props.productName + " Android AR");
        const anchor = document.createElement('a');
        const location = window.location.toString();
        const locationUrl = new URL(location);
        
        const modelUrl = new URL(this.props.glb);
        const link = encodeURIComponent(location);
        const scheme = modelUrl.protocol.replace(':', '');
        //locationUrl.hash = noArViewerSigil;
        const title = encodeURIComponent("title");
        modelUrl.protocol = 'intent://';
        const intent = `${modelUrl.toString()}?link=${link}&title=${title}#Intent;scheme=${scheme};package=com.google.ar.core;action=android.intent.action.VIEW;S.browser_fallback_url=${encodeURIComponent(
            locationUrl.toString()
        )};end;`;
        anchor.setAttribute('href', intent);
        anchor.click();
    }
    clickIos = ()=>{
        ReactGA.initialize('UA-63905846-8');
        ReactGA.pageview(this.props.productName + " Apple AR");
        let alink = document.getElementById("ar-ios-link").click();
    }
}

export default ARButton;