const ProductList = {
            "1":{
                "usdz" : "check the buttons - hardcoded",
                "imageURL":"https://i.imgur.com/NJIMYXE.png",
                "glb" : "Check the buttons - hardcoded",
                "clara" : "ecacaff0-199f-45a2-994b-2bd55d1c19e0",
                "name" :"22 Packages Per Second",
                "brand": "That's how many returns there are on National Returns Day.  Click to see what that looks like in your space right now with our AR experience"
            }
        };
export {ProductList};