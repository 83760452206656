import React from 'react';
import './Product.css';
import 'antd/dist/antd.css';
import {PageHeader} from 'antd';
import ARButton from './ARButton.js';
import {ProductList} from './products/Configs.js';
import ReactGA from 'react-ga';




class Product extends React.Component {
    render(){
        const productId = this.props.match.params.productId;
        const product = ProductList[productId];
        ReactGA.initialize('UA-63905846-8');
        ReactGA.pageview(product.name);
        return(
            <div>
                <PageHeader
                    style={{
                    border: '1px solid rgb(235, 237, 240)',
                    }}
                    onBack={() => window.history.back()}
                    title={product.name}
                />
                              <div>

                </div>
                <div id="noBounce">
                <div id="player" style={{height:400, width:"96%", marginLeft:"auto", marginRight:"auto", background: "#F0F2F5"}}></div>
                </div>
  
                <ARButton productName={"Boxes"} usdz={"https://master.threekit.dev/api/files/3e79f6f8-7e38-4dec-9671-75d86ce10c00/content"} glb={"https://marketing-ar-files.s3.us-east-2.amazonaws.com/Boxes.glb"}></ARButton>
            </div>
        );
    }

    componentDidMount(){
        window
        .threekitPlayer({
          authToken: "743e20c7-1cce-4cdf-93aa-9edf4db88a8d",
          el: document.getElementById("player"),
          assetId: "a3820b06-451f-4d93-b7d0-1cc624698dca"
        })
        .then(function(player) {
          window.player = player;
        });
        document.getElementById("noBounce").addEventListener("touchstart", function() {
            document.body.style.position = "absolute";
            // Stops the pull to refresh functionality
            window.iNoBounce.enable();
        });

        document.getElementById("noBounce").addEventListener("touchend", function() {
            // Test if thet imeout does anything
            setTimeout((document.body.style.position = ""), 3000);

            // Continues the pull to refresh functionality
            window.iNoBounce.disable();
      });
        
    }
}

export default Product
